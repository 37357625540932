const rightSide = document.querySelector('#rightSide');
const eltScroll = document.getElementById('eltScroll');
const eltScrollDesktop = document.getElementById('eltScrollDesktop');
const elements = document.getElementsByClassName(
  'done-section-avantages-list-text'
);
const descriptions = document.getElementsByClassName(
  'done-section-avantages-list-description'
);

let scrollPercentage = () => {
  let h = document.documentElement;
  let st = h.scrollTop || document.body.scrollTop;
  let sh = h.scrollHeight || document.body.scrollHeight;
  let percent = (st / (sh - h.clientHeight)) * 100;

  eltScroll.style.width = `${percent}vw`;
  eltScrollDesktop.style.height = `${percent}vh`;
};

rightSide.onscroll = scrollPercentage;

function showDescription(number) {
  if (!elements?.length || !descriptions?.length) return;

  let activeElement = document.querySelector(
    '.done-section-avantages-list-text.active'
  );
  let visibleDescription = document.querySelector(
    '.done-section-avantages-list-description.visible'
  );

  let elementToActivate = elements[number - 1];
  let elementToView = descriptions[number - 1];

  if (elementToActivate.classList.contains('active')) {
    elementToActivate.classList.remove('active');
    elementToView.classList.remove('visible');
    elementToActivate.blur();
    return;
  }

  if (activeElement && visibleDescription) {
    activeElement.classList.remove('active');
    visibleDescription.classList.remove('visible');
  }

  elementToActivate.classList.add('active');
  elementToView.classList.add('visible');
  elementToActivate.blur();
}

function mobileMenu() {
  let x = document.getElementById('mobileLinks');

  if (x.style.display === 'block') {
    x.style.display = 'none';
  } else {
    x.style.display = 'block';
  }
}

const body = document.body;
const nav = document.querySelector('nav');
const main = document.querySelector('main');
const headerHeight = document.querySelector('header').offsetHeight;
let lastScroll = 0;
window.addEventListener('scroll', () => {
  let currentScroll = window.scrollY;
  if (currentScroll - lastScroll > 0 && currentScroll > 20) {
    nav.classList.add('done-nav-scroll-down');
    nav.classList.remove('done-nav-scroll-up');
  } else if (currentScroll - lastScroll < 0 && currentScroll > 20) {
    // scrolled up -- header show
    nav.classList.add('done-nav-scroll-up');
    nav.classList.remove('done-nav-scroll-down');
  } else {
    nav.classList.remove('done-nav-scroll-up');
    nav.classList.remove('done-nav-scroll-down');
  }

  lastScroll = currentScroll;
});

const button = document.getElementById('callbackCTA');
const buttonRect = button.getBoundingClientRect();

const darkSections = document.querySelectorAll(
  '.done-section-white, .done-section-light'
);

const areIntersecting = (bounds1, bounds2) =>
  bounds1.top < bounds2.bottom && bounds1.bottom > bounds2.top;

document.addEventListener('scroll', () => {
  for (let item of darkSections) {
    const itemRect = item.getBoundingClientRect();

    if (areIntersecting(itemRect, buttonRect)) {
      button.classList.add('done-callback-fix-dark');
      return;
    }
    button.classList.remove('done-callback-fix-dark');
  }
});

function displayMenu(event) {
  const target = event.target;
  const storyPageDetails = target.closest('.done-page-details');
  const storyContent =
    storyPageDetails.getElementsByClassName('stories-content')[0];
  const spanElement = storyPageDetails.querySelector('span');

  storyContent.classList.toggle('stories-show-content');
  spanElement.classList.toggle('stories-see-less');

  if (spanElement.classList.contains('stories-see-less')) {
    storyContent.appendChild(target);
  } else {
    const storyTitle =
      storyPageDetails.getElementsByClassName('stories-title')[0];
    storyTitle.appendChild(target);
  }
}

function photoReveal() {
  let steps = document.querySelectorAll('.done-section-photos-desktop');
  for (let i = 0; i < steps.length; i++) {
    let windowHeight = window.innerHeight;
    let elementTop = steps[i].getBoundingClientRect().top;
    let elementVisible = 150;

    if (elementTop < windowHeight + elementVisible) {
      steps[i].classList.add('done-section-photos-desktop-reveal');
    }
  }
}

window.addEventListener('scroll', photoReveal);

function addHoverClass() {
  const sectionStories = document.querySelector('.done-section-stories');
  sectionStories.classList.add('done-section-stories-hover');
}
